import React, { memo, useCallback } from "react";

const Package = ({ title, description, price, selected, id, onClick }) => {
  const applyIfSelected = useCallback(
    (text, otherwise = "") => (selected ? text : otherwise),
    [selected]
  );

  const applyIfNotSelected = useCallback(
    (text, otherwise = "") => (!selected ? text : otherwise),
    [selected]
  );

  const onPackageClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return (
    <article
      className="flex flex-row package cursor-pointer group"
      onClick={onPackageClick}
    >
      <div className={`w-8 transition duration-200 ease-in-out ${applyIfSelected("bg-5-500")}`}></div>
      <header className={`px-8 py-4 w-full transition duration-200 ease-in-out ${applyIfSelected("bg-4-500")}`}>
        <div className="flex flex-row justify-between items-center">
          <h3
            className={`font-bold text-2xl transition duration-200 ease-in-out ${applyIfNotSelected("group-hover:underline")}  ${applyIfSelected(
              "text-black",
              "text-gray-3"
            )}`}
          >
            {title}
          </h3>
        </div>
        <p className="mt-2 font-light text-sm text-gray-1">{description}</p>
      </header>
    </article>
  );
};

const PackageSelector = ({ packages, onSelection, selected }) => {
  const onPackageClick = useCallback((id, index) => () => {
      onSelection(id, index)
  })

  return (
    <div>
      {packages.map((item, index) => (
        <Package key={index} selected={selected.index === index} {...item} onClick={onPackageClick(item.id, index)} />
      ))}
    </div>
  );
};

export default memo(PackageSelector);
