import React, { memo } from "react";
import { Link } from "gatsby";

import { database } from "../data/db";

import { LogoIcon, PhoneIcon, EmailIcon, FacebookIcon } from "./ui";

const Footer = () => {
  return (
    <footer className="flex flex-col">
      <div className="bg-7-500 lg:bg-5-500 px-4 py-8 xl:px-24 xl:py-8 text-3-500 grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="flex-1 flex justify-center items-center">
          <div className="w-1/3">
            <LogoIcon />
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1 ">
          <p className="mb-8 mt-8 text-base xl:text-lg text-center lg:text-left xl:text-left">
            Impresszum
          </p>
          <div className="flex flex-col flex-1 items-center lg:items-start text-sm justify-center">
            <p>Név: Merkl Kinga egyéni vállalkozó</p>
            <p>Székhely: {database.meta.impress.address}</p>
            <p>Adószám: {database.meta.impress.vatNumber}</p>
            <p>Tárhelyszolgáltató: {database.meta.impress.host}</p>
            <p>
              <Link to="/adatkezeles.pdf" className="hover:underline">Adatkezelési tájékoztató</Link>
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1 lg:items-baseline xl:items-baseline 2xl:items-baseline">
          <p className="mb-8 mt-8 text-center xl:text-left xl:mb-16 text-base xl:text-lg">
            Keress bizalommal!
          </p>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-row justify-centerlg:justify-start items-center">
              <PhoneIcon color="#B7B7A6" />
              <p className="ml-4">{database.meta.impress.tel}</p>
            </div>
            <div className="flex flex-row justify-center lg:justify-start items-center">
              <EmailIcon strokeWidth="1" color="#B7B7A6" />
              <p className="ml-4">{database.meta.impress.email}</p>
            </div>
            <Link
              to={database.meta.social.facebook}
              title="Merkl Kinga facebook"
              className="hover:underline"
            >
              <div className="flex flex-row justify-center lg:justify-start items-center">
                <FacebookIcon color="#B7B7A6" />
                <p className="ml-4">Merkl Kinga</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-5-500 lg:bg-7-500 text-center py-4 text-6-500 px-4 flex flex-col text-xs xl:text-base lg:flex-row lg:justify-center">
        <span className="text-sm">Minden jog fenntartva!</span>
        <span className="text-sm xl:ml-1">
          A weboldalt készítette: Nemeshegyi Csanád
        </span>
      </div>
    </footer>
  );
};

export default memo(Footer);
