import React, { memo } from "react";
import { Link } from "gatsby";
import { database } from "../data/db";

const Blog = () => {
  return (
    <section className="flex flex-col">
      <div className="bg-5-500 xl:bg-1-500 min-h-512 flex flex-col items-center">
        <div className="-mt-16 p-12 bg-5-500 rounded-full shadow-xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="46"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FAFAFA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
        </div>
        <div className="h-full my-16 text-center bg-5-500 py-16 xl:px-32 xl:py-16 rounded-xl flex flex-col justify-center">
          <h3 className="text-lg xl:text-2xl text-6-500  font-light">
            Szeretnél többet megtudni rólam?
          </h3>
          <Link to={database.meta.social.facebook}>
            <p className="text-lg xl:text-2xl mt-8 mb-4 text-gray-0 font-bold hover:underline">
              Vess egy pillantást a blogomra is.
            </p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default memo(Blog);
