import React, { memo, useState, useEffect, useContext } from "react";
import Img from "gatsby-image";

import { useInterval } from "../../../utils/utils";
import { ScrollContext } from "../../../../gatsby-browser";

const Preview = ({ images = [], onImageClick }) => {
  const [state, setState] = useState(0);

  useInterval(() => {
    const newIndex =
      state + 1 === images.length - 1 ? 0 : state + 1;

    setState(newIndex);
  }, 3000);

  useEffect(() => {
    setState(0);
  }, [images]);

  const scrollTop = useContext(ScrollContext);
  
  return (
    <section
      className="w-full h-512 relative cursor-pointer bg-black"
      onClick={onImageClick}
    >
      {images[state] && (
        <>
        <Img
          fixed={images[state].childImageSharp.fixed}
          className={"w-full h-512 left-0 top-0 z-20"}
          style={{
            position: "absolute"
          }}
          imgStyle={{
            animation: "zoom 6s linear infinite",
            objectFit: "contain",
          }}
          alt={`Preview háttérkép`}
          />
        <Img
          fixed={images[state].childImageSharp.fixed}
          className={"w-full h-512 left-0 top-0 z-10"}
          style={{
            position: "absolute"
          }}
          imgStyle={{
            filter: "blur(16px) brightness(0.4)",
            objectPosition: `0% ${25 + scrollTop / 50}%`,
          }}
          alt={`Preview előkép`}
        />
        </>
      )}
    </section>
  );
};

export default memo(Preview);
