import React, { memo, useMemo } from "react";

const Reponsive = ({ modes = [], displayProperty = "block", children }) => {
  const allModes = useMemo(() => ["xs", "sm", "md", "lg", "xl", "2xl"], []);
  const modeClassName = useMemo(
    () =>
      allModes.map(
        (item) => `${item}:${modes.includes(item) ? displayProperty : "hidden"} ${item}:${modes.includes(item) ? "visible" : "invisible"}`
      ).join(" "),
    [modes, allModes, displayProperty]
  );

  return <div className={`hidden invisible ${modeClassName}`}>{children}</div>;
};

export default memo(Reponsive);
