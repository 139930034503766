import React, { memo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Introduction = () => {
  const introductionCloseUpImage = useStaticQuery(graphql`
    query introductionCloseUpImageQuery {
      allFile(
        filter: { sourceInstanceName: { eq: "introductionCloseUpImage" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1200) {
              base64
              aspectRatio
              originalName
              src
            }
          }
        }
      }
    }
  `).allFile.nodes[0].childImageSharp.fluid;

  return (
    <section className="flex flex-col md:flex-row bg-2-500 min-h-512 py-24 px-4 md:px-12 lg:px-12 xl:px-32 xl:pt-16 xl:pb-32">
      <Img
        fluid={introductionCloseUpImage}
        className="flex-1 h-full block rounded-lg shadow-md mb-16 md:mb-0 md:mx-8 lg:mx-16 "
        alt="Merkl Kinga bemutatkozó kép"
      />
      <div className="flex-1">
        <p className="text-base xl:text-lg">
          Ki vagyok én? A fenti kérdésre nem is olyan egyszerű válaszolni.
        </p>
        <p className="mt-2 text-base xl:text-lg">
          A fotózás már gyerekkorom óta érdekelt, ezért igazán szerencsésnek
          mondhatom magamat, hogy azzal foglalkozom, ami egyben a szenvedélyem
          is már régóta.
        </p>
        <br />
        <p className="mt-2 text-base xl:text-lg">
          Nagyon jó érzés és egyben igazán különleges feladat megörökíteni egy
          pillanatot, maradandót alkotni, emlékeket készíteni, hiszen a gyerek
          olyan gyorsan felnő, egy kismamából hamar lesz anyuka, egy
          menyasszonyból pedig feleség. Gyorsan repülnek az évek és gyorsan
          változik minden. A pillanat hamar elszáll, ezeket a pillanatokat
          megőrizni örömmel tölt el, arról nem beszélve, hogy ezáltal a
          képeimmel másoknak is örömet szerzek.
        </p>
        <br />
        <p className="mt-2 text-base xl:text-lg">
          Az is külön öröm, amikor a vendégeim már a barátaim és úgy sétálok
          Gödöllőn főterén, hogy valakivel mindig találkozom, akit egyszer már
          legalább fotóztam és a gyerekek felismernek, köszönnek.
        </p>
        <br />
        <p className="mt-2 text-base xl:text-lg">
          A visszajáró vendégek pedig a legjobb visszajelzés, hiszen így
          tudhatom, hogy jó úton járok még akkor is, ha tudom, hogy sokat kell
          még tanulnom. Amikor pedig egy-egy családi ház falán találkozom az
          általam készített fotókkal, azokra már nincsenek szavak.
        </p>
      </div>
    </section>
  );
};

export default memo(Introduction);
