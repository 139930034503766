import React, { memo } from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const GiftCard = () => {
  const image = useStaticQuery(graphql`
    query ImageQueryGiftCard {
      allFile(filter: { sourceInstanceName: { eq: "giftCard" } }) {
        nodes {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              originalName
              src
            }
          }
        }
      }
    }
  `).allFile.nodes[0].childImageSharp;

  return (
    <section className="bg-0-500 lg:bg-white min-h-512 px-4 pt-16 pb-32 md:px-24 md:py-16 text-white lg:text-gray-1 flex flex-col">
      <header className="font-bold text-2xl text-center">
        Lepd meg te is szeretteidet személyreszabott emlékkel!
      </header>
      <div className="flex flex-col md:flex-row h-full flex-grow mt-8 md:mt-0">
        <div className="flex-1 flex flex-col items-center justify-center">
          <Img fluid={image.fluid} className="w-80 rounded-xl shadow-lg" />
        </div>
        <div className="flex-1 text-lg flex flex-col justify-center mt-8 md:mt-0">
          <h4 className="mb-4 text-xl font-bold">Ajándékutalvány</h4>
          <p className="mb-2">
            Bármely fotózási csomag esetében ajándékutalvány vásárlására is van
            lehetőség. A megvásárolt ajándékutalvány a vásárlástól számított 1
            éven belül, előre egyeztetett időpontban használható fel az
            évszaktól függetlenül. Kivételt képez ez alól a karácsonyi időszak
            (november-december). Ebben az időszakban az utalvány nem váltható
            be!
          </p>
          <p>
            Kismama fotózásra vásárolt utalványok a karácsonyi időszakban is
            felhasználhatóak.
          </p>
        </div>
      </div>
    </section>
  );
};

export default memo(GiftCard);
