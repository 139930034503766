import React, { memo, useCallback, useState } from "react";

import { PackageSelector, InfoBox, PackageDetails, Responsive } from "./ui";
import { Mobile as PricingMobile } from "./ui/pricing";
import { packageSelectorData } from "../data/selectors";

const Pricing = () => {
  const [state, setState] = useState({
    index: 0,
    id: packageSelectorData[0].id,
  });

  const onSelection = useCallback(
    (id, index) => {
      setState({
        id,
        index,
      });
    },
    [setState]
  );

  return (
    <section
      className="min-h-screen"
      title="Csomagok és árak"
      id="csomagokesarak"
    >
      <div className="bg-white px-16 py-8 text-center text-gray-0 flex flex-row justify-center items-center">
        <div className="bg-0-500 rounded-lg shadow-lg py-8 px-8">
          <h2 className="font-bold text-2xl">Csomagok és árak</h2>
          <span className="text-base block mt-2">
            További csomagok megtekintéséhez kattints a kategória nevére!
          </span>
        </div>
      </div>
      <Responsive modes={["xs", "sm", "md"]}>
        <PricingMobile />
      </Responsive>
      <Responsive modes={["lg", "xl", "2xl"]}>
        <div className="flex flex-row">
          <div className="w-1/3 min-h-full bg-gray-0 pb-16">
            <div className="mb-12">
              <PackageSelector
                packages={packageSelectorData}
                onSelection={onSelection}
                selected={state}
              />
              <div className="px-4 mt-16">
                <InfoBox />
              </div>
            </div>
          </div>
          <div className="w-2/3 min-h-full bg-4-500 pb-16">
            <PackageDetails selected={state.id} />
          </div>
        </div>
      </Responsive>
    </section>
  );
};

export default memo(Pricing);
