import React, { memo, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Zoom from "react-medium-image-zoom";

import "react-medium-image-zoom/dist/styles.css";

const Masonry = ({ images = [] }) => {
  const imageRenderer = useCallback((props) => {
    const { photo, margin, direction, top, left, index, key } = props;

    var imgStyle = {
      margin: margin,
      display: "block",
    };

    if (direction === "column") {
      imgStyle.position = "absolute";
      imgStyle.left = left;
      imgStyle.top = top;
    }

    return (
      <Zoom key={key}>
        <img style={imgStyle} {...photo} alt={`masonry image ${props.index}`} />
      </Zoom>
    );
  }, []);

  return (
    <div>
      <Gallery photos={images} renderImage={imageRenderer} />
    </div>
  );
};

export default memo(Masonry);
