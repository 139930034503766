import React, { memo, useCallback, useMemo, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Navbar = () => {
  const iconImage = useStaticQuery(graphql`
    query IconQueryNavbar {
      allFile(filter: { sourceInstanceName: { eq: "iconImage" } }) {
        nodes {
          childImageSharp {
            fixed(width: 120) {
              base64
              aspectRatio
              originalName
              src
            }
          }
        }
      }
    }
  `).allFile.nodes[0].childImageSharp.fixed;

  const [state, setState] = useState(false);

  const menuItems = useMemo(
    () => [
      {
        href: "/",
        content: "Főoldal",
      },
      {
        href: "/#csomagokesarak",
        content: "Csomagok és árak",
      },
      {
        href: "/portfolio",
        content: "Munkáim",
      },
      {
        href: "/introduction",
        content: "Bemutatkozás",
      },
    ],
    [iconImage]
  );

  const closeMenu = useCallback(() => {
    setState(false);
  }, [setState]);

  const openMenu = useCallback(() => {
    setState(true);
  }, [setState]);

  return (
    <>
      <nav className="relative bg-white z-30">
        <div className="hidden md:flex flex-row items-center justify-between px-16 py-4 shadow-md fixed top-0 left-0 w-full bg-white">
          <Link to="/" title="Vissza a főoldalra">
            <Img
              fixed={iconImage}
              className="avatar"
              alt="Merkl Kinga Logo Image"
            />
          </Link>
          <ul className="flex flex-row">
            {menuItems.map((menuItem, index) => (
              <li className="md:block horizontal-spacing hover:underline">
                <Link
                  key={index}
                  to={menuItem.href}
                  activeClassName="font-bold"
                  partiallyActive={menuItem.href !== "/"}
                >
                  {menuItem.content}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="md:hidden py-2 px-8 flex flex-row items-center justify-between">
          <Link to="/">
            <Img fixed={iconImage} className="avatar" alt="Icon kép" />
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            onClick={openMenu}
            className="cursor-pointer"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </div>
        {state && (
          <div
            class={
              "absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            }
          >
            <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div class="pt-5 pb-6 px-5 sm:pb-8">
                <div class="flex items-center justify-between">
                  <div>
                    <Link to="/">
                      <Img fixed={iconImage} className="avatar" alt="Icon kép" />
                    </Link>
                  </div>
                  <div class="-mr-2">
                    <button
                      type="button"
                      class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      onClick={closeMenu}
                    >
                      <span class="sr-only">Close menu</span>
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="mt-6 sm:mt-8">
                  <nav>
                    <div class="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                      {menuItems.map((menuItem, index) => (
                        <Link
                          key={index}
                          href={menuItem.href}
                          class="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"
                          activeClassName="font-bold"
                          partiallyActive
                          onClick={closeMenu}
                        >
                          <div class="ml-4 text-base font-medium text-gray-900">
                            {menuItem.content}
                          </div>
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        )}
      </nav>
      <div className="md:mt-20" />
    </>
  );
};

export default memo(Navbar);
