import React, { memo, useState, useCallback } from "react";
import { packageSelectorData, packagesData } from "../../../data/selectors";

import { Gallery } from "../index";

const Mobile = () => {
  const [state, setState] = useState(packageSelectorData[0].id);

  const applyIfSelected = useCallback(
    (id, text, otherwise = "") => (state === id ? text : otherwise),
    [state]
  );

  const onPackageSelect = useCallback(
    (id) => () => {
      setState(id);
    },
    [setState]
  );

  return (
    <div>
      <header>
        <ul className="flex flex-row overflow-auto">
          {packageSelectorData.map((item) => (
            <li
              key={item.id}
              className={`whitespace-nowrap mr-8 py-4 px-4 cursor-pointer ${applyIfSelected(
                item.id,
                "bg-4-500 font-bold"
              )}`}
              onClick={onPackageSelect(item.id)}
            >
              {item.title}
            </li>
          ))}
        </ul>
      </header>
      <div className="bg-4-500 min-h-512 py-8">
        <h4 className="mx-4 mb-4 px-2 py-2 bg-0-500 text-center font-bold text-white text-xl">
          Csomagok
        </h4>
        <div className="px-4">
          {packagesData[state].packages.map((item) => (
            <article
              key={item.id}
              className="w-full h-full bg-gray-0 rounded-lg shadow px-8 py-6 vertical-spacing"
            >
              <header className="w-full flex flex-row justify-between items-center mb-4">
                <h5 className="text-lg font-bold">{item.title}</h5>
                <p className="text-base font-bold">{item.price}</p>
              </header>
              <div>
                <ul className="">
                  {item.details.map((innerItem, innerIndex) => (
                    <li key={innerIndex} className="text-sm">
                      {innerItem}
                    </li>
                  ))}
                </ul>
              </div>
            </article>
          ))}
        </div>
        <div className="mt-8">
          <Gallery selected={state} />
        </div>
        <h4 className="mx-4 mt-8 mb-4 px-2 py-2 bg-0-500 text-center font-bold text-white text-xl">
          Tudnivalók
        </h4>
        <div className="px-4">
          {packagesData[state].faq.map((item, index) => (
            <dl
              key={index}
              className="w-full bg-gray-0 rounded-lg shadow px-8 py-6 vertical-spacing"
            >
              <dt className="text-lg font-bold mb-2">{item.question}</dt>
              <dd className="text-sm">{item.answer}</dd>
            </dl>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Mobile);
