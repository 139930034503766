import React, { memo } from "react";

const InfoIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0002 42.1667C33.5856 42.1667 42.1668 33.5855 42.1668 23C42.1668 12.4146 33.5856 3.83337 23.0002 3.83337C12.4147 3.83337 3.8335 12.4146 3.8335 23C3.8335 33.5855 12.4147 42.1667 23.0002 42.1667Z"
      stroke="#3E73D1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 30.6667V23"
      stroke="#3E73D1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 15.3334H23.0192"
      stroke="#3E73D1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(InfoIcon);
