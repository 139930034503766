import { graphql, useStaticQuery } from "gatsby";
import React, { memo, useMemo, useCallback, useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { Preview } from "./gallery/index";
import { groupBy } from "../../utils/utils";

const Gallery = ({ selected }) => {
  const imagesRaw = useStaticQuery(graphql`
    query GalleryQuery {
      allFile(
        filter: { sourceInstanceName: { eq: "gallery" } }
        sort: {fields: childImageSharp___fixed___originalName, order: ASC}
      ) {
        nodes {
          id
          relativeDirectory
          childImageSharp {
            fixed(width: 1280) {
              base64
              aspectRatio
              originalName
              src
            }
            fluid(maxWidth: 3200) {
              src
            }
          }
        }
      }
    }
  `).allFile.nodes;
  const images = useMemo(
    () => groupBy(imagesRaw, "relativeDirectory")[selected],
    [imagesRaw, selected]
  );

  const [state, setState] = useState({
    visible: false,
    index: 0,
  });

  const onImageClick = useCallback(() => {
    setState({
      visible: true,
      index: 0,
    });
  }, [setState]);

  const closeLightboy = useCallback(() => {
    setState({
      visible: false,
      index: 0,
    });
  }, [setState]);

  const onMoveNextRequest = useCallback(() => {
    setState({
      ...state,
      index: state.index === images.length - 1 ? 0 : state.index + 1,
    });
  }, [state, setState, images]);

  const onMovePrevRequest = useCallback(() => {
    setState({
      ...state,
      index: state.index === 0 ? images.length - 1 : state.index - 1,
    });
  }, [state, setState, images]);

  useEffect(() => {
    setState({
      visible: false,
      index: 0,
    });
  }, [selected]);

  return (
    <div>
      <Preview images={images} onImageClick={onImageClick} />
      {state.visible && (
        <Lightbox
          mainSrc={images[state.index].childImageSharp.fluid.src}
          nextSrc={
            images[state.index === images.length - 1 ? 0 : state.index + 1]
              .childImageSharp.fluid.src
          }
          prevSrc={
            images[state.index === 0 ? images.length - 1 : state.index - 1]
              .childImageSharp.fluid.src
          }
          onCloseRequest={closeLightboy}
          onMoveNextRequest={onMoveNextRequest}
          onMovePrevRequest={onMovePrevRequest}
          enableZoom={false}
          clickOutsideToClose
        />
      )}
    </div>
  );
};

export default memo(Gallery);
