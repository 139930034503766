import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, Link } from "gatsby";
import CookieConsent from "react-cookie-consent";

const SEO = ({
  title = "Merkl Kinga - Fotós",
  description = "Merkl Kinga - Gödöllőn és környékén családok, gyermekek, csoportok, személyek fotózása.",
}) => {
  const iconImage = useStaticQuery(graphql`
    query IconQuerySEO {
      allFile(filter: { sourceInstanceName: { eq: "iconImage" } }) {
        nodes {
          childImageSharp {
            fixed(width: 120) {
              src
            }
          }
        }
      }
    }
  `).allFile.nodes[0].childImageSharp.fixed;

  return (
    <>
      <Helmet
        title={title}
        htmlAttributes={{
          lang: "hu",
        }}
      >
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          http-equiv="Content-Type"
          content="text/html; charset=ISO-8859-1"
        />
        <meta name="description" content={description} />
        <meta
          name="geo.position"
          content="47.609416788595844; 19.35345026272485"
        />
        <meta name="geo.placename" content="Gödöllő" />
        <meta name="geo.region" content="HU" />
        <meta
          name="keywords"
          content="fotó, fotózás, Gödöllő, család, gyermek, szülinap, születésnap, portré, business portré, üzleti portré, várandós, kimama"
        />
        <meta name="robots" content="index,follow" />

        <meta name="DC.Title" content={title} />
        <meta name="DC.Creator" content="Merkl Kinga" />
        <meta name="DC.Subject" content={description} />
        <meta name="DC.Description" content={description} />
        <meta name="DC.Publisher" content="Merkl Kinga" />
        <meta name="DC.Date" content="2021-02" />
        <meta name="DC.Type" content="Service" />
        <meta name="DC.Format" content="text/html" />
        <meta
          name="DC.Identifier"
          content="https://merklkinga.hu/introduction"
        />
        <meta name="DC.Source" content="https://merklkinga.hu/introduction" />
        <meta name="DC.Language" content="hu" />
        <meta name="DC.Coverage" content="Gödöllő, Hungary" />

        <meta name="og:title" content={title} />
        <meta name="og:url" content="https://merklkinga.hu/introduction" />
        <meta name="og:image" content={iconImage.src} />
        <meta name="og:description" content={description} />
        <meta name="og:locale" content="hu_HU" />
        <meta name="og:site_name" content={title} />
      </Helmet>
      <CookieConsent
        location="bottom"
        buttonText="Elfogadom"
        cookieName="gatsby-gdpr-google-analytics"
      >
        A weboldal cookie-kat használ. További információt az <Link to="/adatkezeles.pdf">Adatkezelési tájékoztatóban</Link> használhatsz
      </CookieConsent>
    </>
  );
};

export default memo(SEO);
