import React, { memo } from "react";

import { InfoIcon, EmailIcon, PhoneIcon } from "./index";

const InfoBox = () => {
  return (
    <article className="bg-white px-4 py-4 rounded-lg shadow-lg flex flex-row">
      <InfoIcon />
      <div className="ml-4">
        <header>
          <p className="font-bold text-xl mb-1">
            Egyedi árajánlatot szeretnél kérni?
          </p>
          <p className="font-light text-sm text-gray-1">
            Keress bizalommal az alábbi elérhetőségek egyikén:
          </p>
        </header>
        <section className="flex flex-col mt-4">
          <div className="flex flex-row items-center">
            <EmailIcon />
            <p className="ml-1">merkl.kinga@gmail.com</p>
          </div>
          <div className="flex flex-row items-center mt-2">
            <PhoneIcon />
            <p className="ml-1">+36 20 886 1666</p>
          </div>
        </section>
      </div>
    </article>
  );
};

export default memo(InfoBox);
