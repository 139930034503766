export const database = {
  packagesData: {
    kismama: {
      selector: {
        title: "Kismamafotózás",
        description: "Ideális kismamák és gyermeket váró párok részére.",
        price: "29.000-57.000 Ft",
      },
      packages: [
        {
          title: "Mini (csak kismama)",
          details: [
            "Fél óra fotózás",
            "Kültéri helyszín",
            "1 szett ruha",
            "10db kiválasztott kép korrigálva, amiből 5 közeli kép retusálva kerül átadásra",
          ],
          price: "29.000 Ft",
        },
        {
          title: "Nagy (Páros)",
          details: [
            "1 óra fotózás",
            "Budapesti műtermi helyszín, öltözővel",
            "2 műtermi ruha",
            "2 átöltözés",
            "20db kiválasztott kép korrigálva, amiből 10 közeli kép retusálva kerül átadásra",
          ],
          price: "40.000 Ft",
        },
        {
          title: "Extra (Páros)",
          details: [
            "1 óra fotózás",
            "Budapesti műtermi helyszín, öltözővel",
            "2 műtermi ruha",
            "Smink",
            "Haj/Frizura",
            "2 átöltözés",
            "30db kiválasztott kép korrigálva, amiből 15 közeli kép retusálva kerül átadásra",
          ],
          price: "57.000 Ft",
        },
      ],
      faq: [
        {
          question: "Hogyan készülj a fotózásra?",
          answer: `Fotózás előtti napon ajánlott a hajmosás. Figyeljetek a kezek ápoltságára! Új szépségápolási terméket ne a fotózás előtt próbáljatok ki. 
              Mezítláb vagy váltócipőben szoktam fotózni, ezért aki nem szeretne mezítláb váltócipőről ne feledkezzen el. 
              Hozzatok a babával kapcsolatos dolgokat. (ruha, kiscipő , ultrahang kép stb)`,
        },
        {
          question: "Hogyan érdemes öltözködni?",
          answer: `Vannak a stúdióban ruhák, amelyek közül lehet választani a csomagnak megfelelő mennyiséget. Ezen kívül vannak más ruhák is, amelyeknek a bérlése 5000 forint 1 alkalomra. 
              Illetve saját ruhát is lehet hozni. Törekedjetek az egyszerűségre és a kényelemre.  A mintás, feliratos ruhák nem mutatnak jól a képeken és elviszi a figyelmet rólatok.  
              Fotózás előtt érdemes egyeztetni velem, szívesen adok tanácsokat. Páros fotók esetén stílusban és színben passzoljatok egymáshoz. Farmert, fehér/fekete pólót például első fotózás alkalmával 
              szoktam javasolni, de természetesen ettől el lehet térni.`,
        },
        {
          question: "Mikor és hogyan adom át a képeket?",
          answer: `Digitálisan küldöm át a kiválasztott képeket, nagy felbontásban a fotózást követő 2 héten belül a megadott e-mail címre. 
              Minden képet egy fotómegosztó oldalon keresztül küldök át (letölteni nem lehet onnan a képeket), ott ki lehet választani azokat, amelyeket retusálok.
               Nyers képeket nem adok át, viszont a csomagárban szereplő képek mennyiségén túl lehet kérni még 2000 forint/darabáron.`,
        },
        {
          question: "Módosítható - lemondható a fotózás időpontja?",
          answer: `Kültéri fotózás esetén ingyenes a lemondás. Műtermi fotózás esetén a fotózás előtti 24 órával lehet lemondani felár nélkül. 
              24 órán túl 5000 forintos terembérleti díjat kell fizetni.`,
        },
      ],
    },
    csaladiesgyermek: {
      selector: {
        title: "Családi- és gyermekfotózás",
        description:
          "Hagyományos fotózás a számotokra legközelebb álló stílusban.",
        price: "29.000-44.500 Ft",
      },
      packages: [
        {
          title: "Normál (maximum 7 fő)",
          details: [
            "1 óra fotózás",
            "Budapesti műtermi helyszín, öltőzővel",
            "20db kiválasztott kép korrigálva, amiből a közeli képek retusálva kerülnek átadásra",
          ],
          price: "42.000 Ft",
        },
        {
          title: "Nagy (7 fő fölött)",
          details: [
            "1 óra fotózás",
            "Budapesti műtermi helyszín, öltőzővel",
            "40db kiválasztott kép korrigálva, amiből a közeli képek retusálva kerülnek átadásra",
          ],
          price: "44.500 Ft",
        },
        {
          title: "Családi fotózás kültéren",
          details: [
            "1 óra fotózás",
            "Gödöllői helyszínnel",
            "20db kiválasztott kép korrigálva, amiből 10 közeli kép retusálva kerül átadásra",
            "Gödöllőn kívüli helyszín esetén 5000 Ft utazási költség kerül felszámolásra",
          ],
          price: "29.000 Ft",
        },
      ],
      faq: [
        {
          question: "Hogyan készülj a fotózásra?",
          answer:
            "Fotózás előtti napon mosson mindenki hajat. Figyeljetek a kezek ápoltságára! Új szépségápolási terméket ne a fotózás előtt próbáljatok ki. Mezítláb vagy váltócipőben szoktam fotózni, ezért aki nem szeretne mezítláb váltócipőről ne feledkezzen el.",
        },
        {
          question: "Hogyan érdemes öltözködni?",
          answer: `Törekedjetek az egyszerűségre és a kényelemre.  A mintás, feliratos ruhák nem mutatnak jól a képeken és elviszi a figyelmet rólatok.  Fotózás előtt érdemes egyeztetni velem, szívesen adok tanácsokat, illetve igény esetén tudok ruhát biztosítani főleg a gyerekeknek. Stílúsban és színben passzoljatok egymáshoz. Farmert, fehér/fekete pólót például első fotózás alkalmával szoktam javasolni, de természetesen ettől el lehet térni. Gyerekeknek érdemes váltóruhát hozni "baleset" esetén.`,
        },
        {
          question: "Mikor és hogyan adom át a képeket?",
          answer: `Digitálisan küldöm át a kiválasztott képeket, nagy felbontásban a fotózást követő 2 héten belül a megadott e-mail címre. Minden képet egy fotómegosztó oldalon keresztül küldök át (letölteni nem lehet onnan a képeket), ott ki lehet választani azokat, amelyeket retusálok. Nyers képeket nem adok át, viszont a csomagárban szereplő képek mennyiségén túl lehet kérni még 2000 forint/darabáron.`,
        },
        {
          question: "Módosítható - lemondható a fotózás időpontja?",
          answer: `Kültéri fotózás esetén ingyenes a lemondás. Műtermi fotózás esetén a fotózás előtti 24 órával lehet lemondani felár nélkül. 24 órán túl 5000 forintos terembérleti díjat kell fizetni.`,
        },
      ],
    },
    ujszulott: {
      selector: {
        title: "Újszülött Lifestyle",
        description:
          "Kellékek nélkül készítem a képeket, természetes fény mellett a saját otthonotokba, a meghittséget helyezve előtérbe.",
        price: "42.500 Ft",
      },
      packages: [
        {
          title: "Újszülött fotózás",
          details: [
            "Nem műtermi körülmények között, úgynevezett lifestyle típusú fotózást csinálok otthoni körülmények között. Újszülöttek esetén házhoz megyek.",
            "A fotózás időtartama rugalmas, igazodva a gyerekhez és/vagy anyához. A kisbaba irányítja a fotózás menetét. Szükség esetén több szünettel (etetés, peluscsere stb).",
            "Időtartam: Nincs időkorlát.  Addig vagyok  helyszínen, amíg nem érzem, hogy lesz megfelelő minőségű és mennyiségű kép, amelyek közül a szülők választhatnak. Ez általában 1-2 óra, de eltarthat 3 órát is.",
            "20 db kiválasztott kép korrigálva, amiből a közeli képek retusálva kerülnek átadásra.",
            "Az ár tartalmazza a kiszállási/utazási költséget Gödöllőn és környékén.",
          ],
          price: "42.500 Ft",
        },
      ],
      faq: [
        {
          question: "Mikor a legjobb az újszülött fotózást készíteni?",
          answer: `Általában a 6-12 nap közé próbáljuk időzíteni a fotózást. Ha kisebb súllyal születik egy gyermek, akkor nagyjából amikor eléri a 3 kg-t, akkor érdemes várni a fotózással. Koraszülött esetén sokszor hetekig nem javasolják a fotózást a 4. hét előtt.
              Azért érdemes ilyen korán készíteni a képeket, mert ilyenkor még nagyon nyugodtak, sokat alszanak.`,
        },
        {
          question: "Hogyan készülj a fotózásra?",
          answer: `Mivel házhoz megyek, fontos, hogy lássam, hogy nagyjából milyen körülmények között lesz a fotózást. Segítség nekem, ha tudtok készíteni képeket a lakásotok legvilágosabb részéről.
            Rugalmas vagyok, igazodom a gyerek alvási szokásaihoz. Evés, alvás után szoktam kezdeni a fotózást, de természetesen fotózás közben is van lehetőség szoptatásra, etetésre ha szükségséges, de érdemes úgy időzíteni, hogy érkezés előtt mindenki pocakja tele. 
            Ezt talán mondanom sem kell, de fontos, hogy a szokásosnál kicsit melegebb legyen a lakás hőmérséklete. Szükségem van egy olyan területre (általában a nappaliban) ahol szabadabban tudok mozogni. Nem okoz gondot a kisebb lakás sem. 
            A fotózás napján mindent úgyanúgy csináljatok, ahogy egyébként is szoktatok. A megszokott rutin szerint csináljatok mindent. Így nem lesz se nektek, sem a babának stresszes a fotózás. 
            Sokszor nagy segítség a cumi. Kérlek, a fotózás időtartama alatt tegyetek kivételt pár percre, akkor is, ha nem cumis a gyerek. `,
        },
        {
          question: "Hogyan érdemes öltözködni?",
          answer: `A szülőkről is készül kép, ezért érdemes úgy készülni, hogy olyan ruhát viseljetek a fotózás alatt, melyek színben, stílusban harmonizálnak egymással. 
            Törekedjetek az egyszerűségre. Kényelmes, otthon ruhát válasszatok amiben jól érzitek magatokat. Fehér, krémszínű, világosbarna színek jól mutatnak otthoni környezetben. 
            A lábak és a kezek ápoltsága fontos.`,
        },
        {
          question: "Mikor és hogyan adom át a képeket?",
          answer: `Digitálisan küldöm át a kiválasztott képeket, nagy felbontásban a fotózást követő 2 héten belül a megadott e-mail címre. 
            Minden képet egy fotómegosztó oldalon keresztül küldök át (letölteni nem lehet onnan a képeket), ott ki lehet választani a azokat, amelyeket retusálok. 
            Nyers képeket nem adok át, viszont a csomagárban szereplő képek mennyiségén túl lehet kérni még 2000 forint/darabáron.`,
        },
      ],
    },
    uzletiportre: {
      selector: {
        title: "Üzleti portré",
        description:
          "Tudtad, hogy a hr-esek többsége elsősorban az önéletrajzodban lévő fotó alapján dönt?",
        price: "6.000-40.000 Ft",
      },
      packages: [
        {
          title: "Business Mini",
          details: [
            "Fél óra fotózás",
            "2 beltéri háttér és 1 kültéri helyszín Gödöllőn",
            "1 átöltözés",
            "3db kiválasztott kép retusálva",
          ],
          price: "18.000 Ft",
        },
        {
          title: "Business Portré",
          details: [
            "Egy óra fotózás",
            "Budapesti műtermi helyszín, öltözővel",
            "15db kiválasztott kép retusálva",
            "Sminkest tudok ajánlani, azt ezen ár nem tartalmazza",
          ],
          price: "40.000 Ft",
        },
        {
          title: "Business One Shot",
          details: [
            "15 perc fotózás",
            "1 beltéri helyszín Gödöllőn",
            "1db kiválasztott kép retusálva",
          ],
          price: "6000 Ft",
        },
      ],
      faq: [
        {
          question: "Hogyan készülj a fotózásra?",
          answer: `Fotózás előtti napon moss hajat. Figyelj a kezed ápoltságára. Smink kérhető a Business portré extra csomag esetén. Ha saját magadnak készíted el a sminket figyelj, hogy ne legyen túl harsány a smink. Új szépségápolási terméket ne a fotózás előtt próbálj ki.`,
        },
        {
          question: "Hogyan érdemes öltözködni?",
          answer: `Törekedj az egyszerűségre. Az üzleties, elegáns öltözéket javaslom, ami pozitív benyomást kelt. A mintás, feliratos ruhák nem mutatnak jól a képeken és elviszi a figyelmet rólad. Fotózás előtt érdemes egyeztetni velem, szívesen adok tanácsokat, illetve igény esetén tudok ruhát biztosítani nőknek 38-as méretben.`,
        },
        {
          question: "Mikor és hogyan adom át a képeket?",
          answer: `Digitálisan küldöm át a kiválasztott képeket, nagy felbontásban a fotózást követő 2 héten belül a megadott e-mail címre. Minden képet egy fotómegosztó oldalon keresztül küldök át (letölteni nem lehet onnan a képeket), ott ki lehet választani azokat, amelyeket retusálok. Nyers képeket nem adok át, viszont a csomagárban szereplő képek mennyiségén túl lehet kérni még 2000 forint/darabáron.`,
        },
        {
          question: "Módosítható - lemondható a fotózás időpontja?",
          answer: `Kültéri fotózás esetén ingyenes a lemondás. Műtermi fotózás esetén a fotózás előtti 24 órával lehet lemondani felár nélkül. 
              24 órán túl 5000 forintos terembérleti díjat kell fizetni.`,
        },
      ],
    },
    portre: {
      selector: {
        title: "Portré",
        description:
          "Nem annyira hivatalos, mint az üzleti fotózás, de ezeket a képeket is számos helyen felhasználhatod.",
        price: "28.000-40.000 Ft",
      },
      packages: [
        {
          title: "Portré",
          details: [
            "Fél óra fotózás",
            "Csak kültéri helyszín",
            "10db kiválasztott kép retusálva",
          ],
          price: "28.000 Ft",
        },
        {
          title: "Portré extra",
          details: [
            "Egy óra fotózás",
            "Budapesti műtermi helyszín, öltözővel",
            "2-3 átöltözés",
            "20db kiválasztott kép retusálva",
            "Sminkest tudok ajánlani, azt ezen ár nem tartalmazza",
          ],
          price: "40.000 Ft",
        },
      ],
      faq: [
        {
          question: "Hogyan készülj a fotózásra?",
          answer: `Fotózás előtti napon moss hajat. Figyelj a kezed ápoltságára. Smink kérhető a Business portré extra csomag esetén. Ha saját magadnak készíted el a sminket figyelj, hogy ne legyen túl harsány a smink. Új szépségápolási terméket ne a fotózás előtt próbálj ki.`,
        },
        {
          question: "Hogyan érdemes öltözködni?",
          answer: `Törekedj az egyszerűségre. Az üzleties, elegáns öltözéket javaslom, ami pozitív benyomást kelt. A mintás, feliratos ruhák nem mutatnak jól a képeken és elviszi a figyelmet rólad. Fotózás előtt érdemes egyeztetni velem, szívesen adok tanácsokat, illetve igény esetén tudok ruhát biztosítani nőknek 38-as méretben.`,
        },
        {
          question: "Mikor és hogyan adom át a képeket?",
          answer: `Digitálisan küldöm át a kiválasztott képeket, nagy felbontásban a fotózást követő 2 héten belül a megadott e-mail címre. 
          Minden képet egy fotómegosztó oldalon keresztül küldök át (letölteni nem lehet onnan a képeket), ott ki lehet választani a azokat, 
          amelyeket retusálok. Nyers képeket nem adok át, viszont a csomagárban szereplő képek mennyiségén túl lehet kérni még 2000 forint/darabáron.`,
        },
        {
          question: "Módosítható - lemondható a fotózás időpontja?",
          answer: `Kültéri fotózás esetén ingyenes a lemondás. Műtermi fotózás esetén a fotózás előtti 24 órával lehet lemondani felár nélkül. 
              24 órán túl 5000 forintos terembérleti díjat kell fizetni.`,
        },
      ],
    },
    paros: {
      selector: {
        title: "Páros",
        description:
          "Ha csak szeretnétek a közös szelfiken túl, profi képekkel megörökíteni a kapcsolatotok pillanatait, akkor bízzátok magatokat rám.",
        price: "28.000-40.000 Ft",
      },
      packages: [
        {
          title: "Páros mini",
          details: [
            "Fél óra fotózás",
            "Kültéri helyszínen",
            "10db kiválasztott kép retusálva",
          ],
          price: "28.000 Ft",
        },
        {
          title: "Páros extra",
          details: [
            "Egy óra fotózás",
            "Budapesti műtermi helyszín, öltözővel vagy gödöllői kültéri helyszín",
            "2 átöltözés műtermi fotózás esetén",
            "20db kiválasztott kép retusálva",
          ],
          price: "40.000 Ft",
        },
      ],
      faq: [
        {
          question: "Hogyan készülj a fotózásra?",
          answer: `Fotózás előtti napon mossatok hajat, mert ha aznap mostok, akkor nagyon fog szállni. Ha fodrászhoz mentek, akkor természetesen lehet aznap. Figyeljetek a kezek ápoltságára. 
          Smink javasolt. Ha saját magadnak készíted el a sminket figyelj, hogy ne legyen túl harsány a smink. Új szépségápolási terméket ne a fotózás előtt próbáljatok ki.`,
        },
        {
          question: "Hogyan érdemes öltözködni?",
          answer: `Törekedj az egyszerűségre. Páros extra esetén hozhattok több különböző stílusú szettet. (elegáns, kényelmes..stb) A mintás, feliratos ruhák nem mutatnak jól a képeket 
          és elviszi a figyelmet rólad. Fotózás előtt érdemes egyeztetni velem, szívesen adok tanácsokat.`,
        },
        {
          question: "Mikor és hogyan adom át a képeket?",
          answer: `Digitálisan küldöm át a kiválasztott képeket, nagy felbontásban a fotózást követő 2 héten belül a megadott e-mail címre. 
          Minden képet egy fotómegosztó oldalon keresztül küldök át (letölteni nem lehet onnan a képeket) és ott ki lehet választani a képeket, 
          amit retusálok. Nyers képeket nem adok át, viszont a csomagárban szereplő képek mennyiségén túl lehet kérni még képeket 2000 forint/darabáron.`,
        },
        {
          question: "Módosítható - lemondható a fotózás időpontja?",
          answer: `Kültéri fotózás esetén ingyenes a lemondás. Műtermi fotózás esetén a fotózás előtti 24 órával lehet lemondani felár nélkül. 24 órán túl 5000 forintos terembérleti díjat kell fizetni.`,
        },
      ],
    },
    atvaltoztato: {
      selector: {
        title: "Önbizalomnövelő fotózás",
        description:
          "Nőkről nőknek. Tökéletes választás akár ajándékutalvány formájában.",
        price: "57.000 Ft",
      },
      packages: [
        {
          title: "Önbizalomnövelő extra",
          details: [
            "Műtermi helyszín, öltözővel",
            "1 óra fotózás",
            "2-3 ruha a Juci's Fashion&Balance üzlet kínálatából",
            "20 kiválasztott kép retusálva",
          ],
          price: "57.000 Ft",
        },
      ],
      faq: [
        {
          question: "Hogyan készülj a fotózásra?",
          answer: `Fotózás előtti napon moss hajat. Figyelj a kezed ápoltságára. Ne sminkeld ki magad, mert mindkét csomagban benne van a smink.`,
        },
        {
          question: "Hogyan érdemes öltözködni?",
          answer: `Törekedj az egyszerűségre. Extra csomag esetén hozhatsz több különböző stílusú szettet. (elegáns, sexy, stb) A mintás, feliratos ruhák nem mutatnak jól a képeken 
          és elviszi a figyelmet rólad. Fotózás előtt érdemes egyeztetni velem, szívesen adok tanácsokat.  Illetve választani lehet 2-3 ruhát a Juci' Fashion and Balance boltból a fotózás időtartamára. Magassarkú cipőt hozz a fotózásra.`,
        },
        {
          question: "Mikor és hogyan adom át a képeket?",
          answer: `Digitálisan küldöm át a kiválasztott képeket, nagy felbontásban a fotózást követő 2 héten belül a megadott e-mail címre. 
          Minden képet egy fotómegosztó oldalon keresztül küldök át (letölteni nem lehet onnan a képeket), ott ki lehet választani azokat, 
          amelyeket retusálok. Nyers képeket nem adok át, viszont a csomagárban szereplő képek mennyiségén túl lehet kérni még 2000 forint/darabáron.`,
        },
        {
          question: "Módosítható - lemondható a fotózás időpontja?",
          answer: `Kültéri fotózás esetén ingyenes a lemondás. Műtermi fotózás esetén a fotózás előtti 24 órával lehet lemondani felár nélkül. 24 órán túl 5000 forintos terembérleti díjat kell fizetni.`,
        },
      ],
    },
  },
  meta: {
    impress: {
      name: "Merkl Kinga",
      address: "2100 Gödöllő, Kelemen László utca 14./C",
      email: "merkl.kinga@gmail.com",
      tel: "+36208861666",
      vatNumber: "53706645-1-33",
      host: "Netlify, Inc. (San Francisco, Egyesült Államok)",
    },
    social: {
      facebook: "https://www.facebook.com/merklkingaphotography",
    },
  },
};
