import React, { memo, createRef } from "react";
import { Transition, animated } from "react-spring/renderprops";

import { Gallery } from "./index";
import { packagesData } from "../../data/selectors";

const PackageDetails = ({ selected }) => {
  return (
    <section className="pt-6">
      <div className="px-20">
        <h4 className="mx-32 px-2 py-2 text-center font-bold text-black text-2xl">
          Csomagok
        </h4>
      </div>
      <section className="px-16 my-8 grid grid-cols-2 gap-4 relative">
        <Transition
          items={packagesData[selected].packages}
          keys={packagesData[selected].packages.map((item) => item.id)}
          from={({ x, y }) => ({
            x,
            y,
            position: "absolute",
            transform: "scale(0.3)",
            opacity: 0,
          })}
          enter={{ position: "relative", transform: "scale(1)", opacity: 1 }}
          leave={({ x, y }) => ({
            x,
            y,
            position: "absolute",
            transform: "scale(0.3)",
            opacity: 0,
          })}
        >
          {(item) => (style) => (
            <animated.div
              key={item.id}
              ref={createRef()}
              className="w-full h-full bg-gray-0 rounded-lg shadow px-8 py-6"
              style={{ ...style }}
            >
              <header className="w-full flex flex-row justify-between items-center mb-4">
                <h5 className="text-lg font-bold">{item.title}</h5>
                <p className="text-base font-bold whitespace-nowrap">{item.price}</p>
              </header>
              <div>
                <ul className="">
                  {item.details.map((innerItem, innerIndex) => (
                    <li key={innerIndex} className="text-sm">
                      {innerItem}
                    </li>
                  ))}
                </ul>
              </div>
            </animated.div>
          )}
        </Transition>
      </section>
      <Gallery selected={selected} />
      <div className="px-20 mt-12">
        <h4 className="mx-32 px-2 py-2 text-center font-bold text-black text-2xl">
          Tudnivalók
        </h4>
      </div>
      <section className="flex flex-col px-12 my-8">
        {packagesData[selected].faq.map((item, index) => (
          <dl
            key={index}
            className="w-full bg-gray-0 rounded-lg shadow px-8 py-6 vertical-spacing"
          >
            <dt className="text-lg font-bold mb-2">{item.question}</dt>
            <dd className="text-sm">{item.answer}</dd>
          </dl>
        ))}
      </section>
    </section>
  );
};

export default memo(PackageDetails);
