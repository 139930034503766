import { database } from "./db";

export const packageSelectorData = Object.entries(
  database.packagesData
).map((item) => ({ ...item[1].selector, id: item[0] }));

export const packagesData = Object.fromEntries(
  Object.entries(database.packagesData).map((item) => [
    item[0],
    {
      ...item[1],
      packages: [
        ...item[1].packages.map((packageItem, index) => ({
          ...packageItem,
          id: `${item[0]}${index}`,
        })),
      ],
    },
  ])
);

export const packageTitlesAndIds = Object.entries(
  database.packagesData
).map((item) => ({ title: item[1].selector.title, id: item[0] }));
